import React, {useState} from 'react';

// Styles
import styles from './Banner.module.css';

// Component
import HeadingOne from '../HeadingOne';
import HeadingTwo from '../HeadingTwo';
import ButtonOne from '../ButtonOne';

// Images and Videos
import BannerVideo from '../../assets/videos/banner_video_2.MP4';
import BannerImage from '../../assets/images/mobile_banner_image.jpg';

function Banner() {
    const [height, setHeight] = useState(window.innerHeight);
    console.log(height);

    const handleOrderNowClick = () => {
        window.location.href = 'https://order.toasttab.com/online/rockys-2-1622-south-wayne-road';
    }

    const handleDoordashClick = () => {
        window.location.href = "https://www.doordash.com/store/rocky's-coney-&-grill-family-dining-westland-25555901/";
    }

    return ( 
        <div className={styles.banner} style={{height: `${height}px`}}>
            {/* BANNER VIDEO */}
            <div className={styles.bannerVideo}>
                <video className={styles.bannerVideoContent} autoPlay muted loop poster={BannerImage}>
                    <source src={BannerVideo} type="video/webm" />
                    Your browser is not supported!
                </video>
            </div> 

            {/* BANNER CONTENT */}
            <div className={styles.bannerContentContainer}>
                <HeadingOne>ROCKY’S FAMILY DINING</HeadingOne>
                <HeadingTwo additionalClassNames={styles.bannerSecondaryTitle}>HOME STYLE COOKING MADE FOR YOU</HeadingTwo>
                
                <div className={styles.bannerButtonContainer}>
                    <ButtonOne additionalClassNames={styles.bannerButton} text={'ORDER NOW'} onClick={handleOrderNowClick} />
                    <ButtonOne additionalClassNames={styles.bannerButton} text={'DOORDASH'} onClick={handleDoordashClick}/>
                </div>
            </div>
        </div>
     );
}

export default Banner;