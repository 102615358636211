import React from 'react';
import styles from './home.module.css';

// Components
import Banner from '../components/Banner';

import HeadingOne from '../components/HeadingOne';
import HeadingTwo from '../components/HeadingTwo';
import HeadingThree from '../components/HeadingThree';
import ParagraphOne from '../components/ParagraphOne';

import ButtonTwo from '../components/ButtonTwo';

import CustomerReviewCard from '../components/CustomerReviewCard';

import CustomCarousel from '../components/CustomCarousel';

// Images and videos
import IntroImageOne from '../assets/images/media_page_pictures/home_burger.JPG';
import IntroImageTwo from '../assets/images/media_page_pictures/v_gyro.JPG';
import IntroImageThree from '../assets/images/media_page_pictures/s_pasta.JPG';

import BestInHouseImageOne from '../assets/images/media_page_pictures/h_fish_chips_two.JPG';
import BestInHouseImageTwo from '../assets/images/sirloin_steak.JPG';
import BestInHouseImageThree from '../assets/images/media_page_pictures/rockys_burger_two.jpg';

import AboutUsImage from '../assets/images/restaurant_photo.JPG';

import ReviewPersonOne from '../assets/images/review_people/brian.jpg';
import ReviewPersonTwo from '../assets/images/review_people/erin.jpg';
import ReviewPersonThree from '../assets/images/review_people/ryan.jpg';
import ReviewPersonFour from '../assets/images/review_people/bruce.jpg';

function Home() {
    const handleOrderNowClick = () => {
        window.location.href = 'https://order.toasttab.com/online/rockys-2-1622-south-wayne-road';
    }

    const handleDoordashClick = () => {
        window.location.href = "https://www.doordash.com/store/rocky's-coney-&-grill-family-dining-westland-25555901/";
    }

    return ( 
        <div>
            {/* BANNER */}
            <Banner/>

            {/* INTRO SECTION */}
            <div className={styles.introSection}>
                {/* Left section */}
                <div className={styles.introSectionLeft}>
                    <HeadingTwo>A DESTINATION: WHERE AMERICAN, GREEK, AND ITALIAN FLAVORS UNITE!</HeadingTwo>

                    <ParagraphOne>Discover a culinary fusion like no other at our diner. From American classics like juicy burgers to Greek delights such as gyros and Italian specialties like cheesy lasagna, our menu offers a delicious blend of flavors. Located in 1622 S. Wayne Rd. Westland Michigan, our vibrant atmosphere is perfect for any occasion. Come experience the best of American, Greek, and Italian cuisine in one place!</ParagraphOne>

                    <ButtonTwo additionalClassNames={styles.bannerButton} text={'ORDER NOW'} onClick={handleOrderNowClick}/>
                    <ButtonTwo additionalClassNames={styles.bannerButton} text={'DOORDASH'} onClick={handleDoordashClick} />

                </div>

                {/* Rgith section */}
                <div className={styles.introSectionRight}>
                    <div className={styles.introSectionRightImageContainer}> 
                        <img src={IntroImageOne} className={styles.introSectionRightImage} alt="" />
                    </div>

                    <div className={styles.introSectionRightImageContainer}> 
                        <img src={IntroImageTwo} className={styles.introSectionRightImage} alt="" />
                    </div>

                    <div className={styles.introSectionRightImageContainer}> 
                        <img src={IntroImageThree} className={styles.introSectionRightImage} alt="" />
                    </div>
                </div>
            </div>

            {/* BEST IN HOUSE SECTION */}
            <div className={styles.bestInHouseSection}>
                {/* Best in house item ONE */}
                <div className={styles.bestInHouseItem}>
                    <div className={styles.bestInHouseItemImageContainer}>
                        <img src={BestInHouseImageOne} className={styles.introSectionRightImage} alt="" />
                    </div>
                    <div className={styles.bestInHouseItemDescription}>
                        <HeadingOne>BEST IN HOUSE</HeadingOne>
                        <HeadingThree additionalClassNames={styles.bestInHouseItemTitle}>Fish n Chips</HeadingThree>
                        <ParagraphOne additionalClassNames={styles.bestInHouseItemDescriptionParagraph}>Fish n Chips, an all-time favorite at Rocky's Family Dining, features flaky Alaskan cod hand-dipped in a perfectly seasoned batter and deep-fried to a golden brown. This classic dish is served with a generous portion of crispy, golden fries, creating a delightful combination of textures and flavors. The tender, flavorful fish pairs wonderfully with the crunchy fries, making Fish n Chips a beloved choice for seafood lovers and comfort food enthusiasts alike.</ParagraphOne>
                    </div>
                </div>
                
                {/* Best in house item TWO */}
                <div className={styles.bestInHouseItem}>
                    <div className={styles.bestInHouseItemImageContainer}>
                        <img src={BestInHouseImageTwo} className={styles.introSectionRightImage} alt="" />
                    </div>
                    <div className={styles.bestInHouseItemDescription}>
                        <HeadingThree additionalClassNames={styles.bestInHouseItemTitle}>Chopped Sirloin Steak</HeadingThree>
                        <ParagraphOne additionalClassNames={styles.bestInHouseItemDescriptionParagraph}>The Chopped Sirloin Steak at Rocky's Family Dining is a hearty and flavorful dish, featuring a perfectly cooked sirloin steak that is juicy and tender. It's topped with a generous serving of sautéed mushrooms and onions, which add a savory, caramelized richness that enhances the steak's natural flavors. This classic combination makes the Chopped Sirloin Steak a satisfying and delicious choice for any meat lover.</ParagraphOne>
                    </div>
                </div>

                {/* Best in house item THREE */}
                <div className={styles.bestInHouseItem}>
                    <div className={styles.bestInHouseItemImageContainer}>
                        <img src={BestInHouseImageThree} className={styles.introSectionRightImage} alt="" />
                    </div>
                    <div className={styles.bestInHouseItemDescription}>
                        <HeadingThree additionalClassNames={styles.bestInHouseItemTitle}>Rocky’s Burger</HeadingThree>
                        <ParagraphOne additionalClassNames={styles.bestInHouseItemDescriptionParagraph}>Rocky's Burger, the best seller at Rocky's Family Dining, is an American delight known for its rich flavors and premium ingredients. This signature burger features a juicy beef patty, perfectly grilled and topped with a fried egg and crispy bacon for an extra layer of indulgence. Fresh lettuce, ripe tomato, and sliced onion add a refreshing crunch, while creamy mayonnaise and melted American cheese bring a delicious, tangy richness. All these ingredients are in between a toasted, buttery bun, creating a balanced blend of textures and tastes that make Rocky's Burger an irresistible favorite. </ParagraphOne>
                    </div>
                </div>
            </div>

            {/* DAILY SPECIALS SECTION */}
            <div className={styles.dailySpecialsSection}>
                <div className={styles.dailySpecialsSectionContainer}>
                    <HeadingTwo>DAILY SPECIALS</HeadingTwo>
                    
                    <CustomCarousel>
                        <div>
                            {/* Monday */}
                            <HeadingTwo additionalClassNames={styles.dayTitle}>Monday</HeadingTwo>
                            <div className={styles.dailySpecialItemSectionContainer}>
                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Dinner Specials</HeadingThree>
                                    <ParagraphOne>Half Ribs</ParagraphOne>
                                    <ParagraphOne>Stuffed Cabbage</ParagraphOne>
                                    <ParagraphOne>Spaghetti Meatballs</ParagraphOne>
                                </div>

                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Soups of the day</HeadingThree>
                                    <ParagraphOne>Chicken Noodle</ParagraphOne>
                                    <ParagraphOne>Chicken Lemon Rice</ParagraphOne>
                                    <ParagraphOne>Cabbage Soup</ParagraphOne>
                                </div>
                            </div>
                        </div>

                        <div>
                            {/* Tuesday */}
                            <HeadingTwo additionalClassNames={styles.dayTitle}>Tuesday</HeadingTwo>
                            <div className={styles.dailySpecialItemSectionContainer}>
                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Dinner Specials</HeadingThree>
                                    <ParagraphOne>Beef Stroganoff</ParagraphOne>
                                    <ParagraphOne>Greek Trio</ParagraphOne>
                                    <ParagraphOne>Lasagna</ParagraphOne>
                                </div>

                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Soups of the day</HeadingThree>
                                    <ParagraphOne>Chicken Noodle</ParagraphOne>
                                    <ParagraphOne>Chicken Lemon Rice</ParagraphOne>
                                    <ParagraphOne>Cabbage Soup</ParagraphOne>
                                    <ParagraphOne>Potato Cream</ParagraphOne>
                                </div>
                            </div>
                        </div>

                        <div>
                            {/* Wednesday */}
                            <HeadingTwo additionalClassNames={styles.dayTitle}>Wednesday</HeadingTwo>
                            <div className={styles.dailySpecialItemSectionContainer}>
                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Dinner Specials</HeadingThree>
                                    <ParagraphOne>Stuffed Cabbage</ParagraphOne>
                                    <ParagraphOne>Moussaka</ParagraphOne>
                                    <ParagraphOne>Yankee Pot Roast</ParagraphOne>
                                </div>

                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Soups of the day</HeadingThree>
                                    <ParagraphOne>Chicken Noodle</ParagraphOne>
                                    <ParagraphOne>Chicken Lemon Rice</ParagraphOne>
                                    <ParagraphOne>Cabbage Soup</ParagraphOne>
                                    <ParagraphOne>Broccoli Cream</ParagraphOne>
                                </div>
                            </div>
                        </div>

                        <div>
                            {/* Thursday */}
                            <HeadingTwo additionalClassNames={styles.dayTitle}>Thursday</HeadingTwo>
                            <div className={styles.dailySpecialItemSectionContainer}>
                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Dinner Specials</HeadingThree>
                                    <ParagraphOne>Beef Tips</ParagraphOne>
                                    <ParagraphOne>C. Beef Cabbage</ParagraphOne>
                                    <ParagraphOne>Pork Chop Dinner</ParagraphOne>
                                </div>

                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Soups of the day</HeadingThree>
                                    <ParagraphOne>Chicken Noodle</ParagraphOne>
                                    <ParagraphOne>Chicken Lemon Rice</ParagraphOne>
                                    <ParagraphOne>Cabbage Soup</ParagraphOne>
                                    <ParagraphOne>Beef Barley</ParagraphOne>
                                </div>
                            </div>
                        </div>

                        <div>
                            {/* Friday */}
                            <HeadingTwo additionalClassNames={styles.dayTitle}>Friday</HeadingTwo>
                            <div className={styles.dailySpecialItemSectionContainer}>
                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Dinner Specials</HeadingThree>
                                    <ParagraphOne>Fish and Chips</ParagraphOne>
                                    <ParagraphOne>Greek Trio</ParagraphOne>
                                    <ParagraphOne>Wild Salmon</ParagraphOne>
                                </div>

                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Soups of the day</HeadingThree>
                                    <ParagraphOne>Chicken Noodle</ParagraphOne>
                                    <ParagraphOne>Chicken Lemon Rice</ParagraphOne>
                                    <ParagraphOne>Cabbage Soup</ParagraphOne>
                                    <ParagraphOne>Clam Chowder</ParagraphOne>
                                </div>
                            </div>
                        </div>

                        <div>
                            {/* Saturday */}
                            <HeadingTwo additionalClassNames={styles.dayTitle}>Saturday</HeadingTwo>
                            <div className={styles.dailySpecialItemSectionContainer}>
                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Dinner Specials</HeadingThree>
                                    <ParagraphOne>Beef Stroganoff</ParagraphOne>
                                    <ParagraphOne>Chicken Alfredo</ParagraphOne>
                                    <ParagraphOne>Half Ribs</ParagraphOne>
                                </div>

                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Soups of the day</HeadingThree>
                                    <ParagraphOne>Chicken Noodle</ParagraphOne>
                                    <ParagraphOne>Chicken Lemon Rice</ParagraphOne>
                                    <ParagraphOne>Cabbage Soup</ParagraphOne>
                                </div>
                            </div>
                        </div>

                        <div>
                            {/* Sunday */}
                            <HeadingTwo additionalClassNames={styles.dayTitle}>Sunday</HeadingTwo>
                            <div className={styles.dailySpecialItemSectionContainer}>
                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Dinner Specials</HeadingThree>
                                    <ParagraphOne>Roasted Turkey Dinner</ParagraphOne>
                                    <ParagraphOne>Chicken Strip Fry</ParagraphOne>
                                    <ParagraphOne>Spinach Pie</ParagraphOne>
                                </div>

                                <div className={styles.dailySpecialItemSection}>
                                    <HeadingThree>Soups of the day</HeadingThree>
                                    <ParagraphOne>Chicken Noodle</ParagraphOne>
                                    <ParagraphOne>Chicken Lemon Rice</ParagraphOne>
                                    <ParagraphOne>Cabbage Soup</ParagraphOne>
                                </div>
                            </div>
                        </div>
                    </CustomCarousel>
                </div>
            </div>

            {/* ABOUT US */}
            <div className={styles.aboutUsSection}>
                <HeadingTwo additionalClassNames={styles.aboutUsSectionTitle}>ABOUT US</HeadingTwo>
                <div className={styles.aboutUsSectionContent}>
                    <div className={styles.aboutUsSectionContentLeft}>
                        <HeadingThree additionalClassNames={styles.aboutUsSectionContentTitle}>Welcome to Rocky’s Family Dining!</HeadingThree>

                        <ParagraphOne>OUR RESTAURANT IS A COMBINATION OF AMERICAN, GREEK, AND ITALIAN RICH CULINARY SPECIALTIES AND WARM HOSPITALITY.</ParagraphOne>

                        <ParagraphOne>WE CRAFT DISHES INSPIRED BY GENERATIONS OF TRADITION, WHILE WE ENSURE EVERY GUEST FEELS LIKE FAMILY.</ParagraphOne>

                        <ParagraphOne>STEP INTO OUR COZY AMERICAN SPACE ADORNED WITH TRADITIONAL DÉCOR AND SAVOR OUR VIBRANT FLAVORS.</ParagraphOne>

                        <ParagraphOne>WHETHER IT'S A CASUAL MEAL OR A SPECIAL CELEBRATION, JOIN US TO EXPERIENCE THE BEAUTY OF ROCKY'S, ONE DELICIOUS DISH AT A TIME.</ParagraphOne>

                        <ParagraphOne>THERE'S MUCH TO SEE HERE. SO, TAKE YOUR TIME, LOOK AROUND, AND LEARN ALL THERE IS TO KNOW ABOUT US. WE HOPE YOU ENJOY OUR SITE AND WE CAN’T WAIT TO SEE YOU SOON.</ParagraphOne>
                    </div>

                    <div className={styles.aboutUsSectionContentRight}>
                        <div className={styles.aboutUsSectionImageContainer}>
                            <img src={AboutUsImage} className={styles.introSectionRightImage} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            {/* GIFT CARD SECTION */}
            {/* <div className={styles.giftCardSection}>
                <HeadingTwo additionalClassNames={styles.giftCardSectionTitle}>GIVE THE GIFT OF FLAVOR</HeadingTwo>

                <img src={GiftCard} className={styles.gitCardImage} alt="" />

                <ButtonOne additionalClassNames={styles.giftCardButton} text={'ORDER NOW'} />
            </div> */}

            {/* REVIEWS SECTION */}
            <div className={styles.customerReviewsSection}>
                <HeadingTwo additionalClassNames={styles.customerReviewsSectionTitle}>CUSTOMER REVIEWS</HeadingTwo>

                <div className={styles.customerReviewsContainer}>
                    <CustomerReviewCard imgSrc={ReviewPersonOne} name={'Brian S.'} stars={5}>
                        Great service is the reason I love Rocky's Family Dining. I go for breakfast a couple times a week and have never had a bad experience.

                    </CustomerReviewCard>

                    <CustomerReviewCard imgSrc={ReviewPersonTwo} name={'Erin C.'} stars={5}>
                        This is one of our frequent places to eat. Usually breakfast. Everything here is good from the eggs Benedict to the pancakes. 
                    </CustomerReviewCard>

                    <CustomerReviewCard imgSrc={ReviewPersonThree} name={'Ryan C.'} stars={5}>
                        Best restaurant in the area! staff and food are phenomenal.. chicken alfredo and chicken lemon rice soup is a ultimate meal.
                    </CustomerReviewCard>

                    <CustomerReviewCard imgSrc={ReviewPersonFour} name={'Bruce F.'} stars={5}>
                        Eggs are always perfect and the hash browns just the way we like them. I'll be back on a regular basis. They do things right!
                    </CustomerReviewCard>
                </div>

                <div className={styles.customerReviewsContainerMobile}>
                    <CustomCarousel>
                        <CustomerReviewCard imgSrc={ReviewPersonOne} name={'Brian S.'} stars={5}>
                            Great service is the reason I love Rocky's Family Dining. I go for breakfast a couple times a week and have never had a bad experience.

                        </CustomerReviewCard>

                        <CustomerReviewCard imgSrc={ReviewPersonTwo} name={'Erin C.'} stars={5}>
                            This is one of our frequent places to eat. Usually breakfast. Everything here is good from the eggs Benedict to the pancakes. 
                        </CustomerReviewCard>

                        <CustomerReviewCard imgSrc={ReviewPersonThree} name={'Ryan C.'} stars={5}>
                            Best restaurant in the area! staff and food are phenomenal.. chicken alfredo and chicken lemon rice soup is a ultimate meal.
                        </CustomerReviewCard>

                        <CustomerReviewCard imgSrc={ReviewPersonFour} name={'Bruce F.'} stars={5}>
                            Eggs are always perfect and the hash browns just the way we like them. I'll be back on a regular basis. They do things right!
                        </CustomerReviewCard>
                    </CustomCarousel>
                </div>
            </div>
        </div>
     );
}

export default Home;